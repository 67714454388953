.navController{
    position: absolute;
    left: -224px;
    width: 0px;
    display: none;
}

.navController.closed{
    position: absolute;
    animation: fly-out 0.5s ease forwards;    
}

.navController.notClosed{
    min-height: 100%;
    padding: 16px 8px 0px 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    animation: fly-in 0.5s ease-in-out forwards;
}

@keyframes fly-out {
    0% {
      left: 0%;
       width: 224px;
    }
    100% {
      left: -224px;
      width: 0px;
      display: none;
    }
}

@keyframes fly-in {
    0% {
      left: -244px;
      width: 0px;
    }
    100% {
      left: 0%;
      width: 224px;
    }
}




.navController .italic{
    font-style: italic;
}

.navController-pageSelectors{
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
}

.navController-agentSel-col{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    /* border: 1px solid var(--mainDark);
    border-radius: 15px;
    padding: 5px››; */
}

.navController-agentSel-col::-webkit-scrollbar {
    width: 0px;
    background: transparent; 
  }
.navController-agentSel-col::-webkit-scrollbar-track {   
    background: transparent; 
}


.navController-pill{
    width: 100%;
    height: 36px;
    padding: 6px 12px;
    background-color: var(--lighterBackground);
    border-radius: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background-color: transparent;
}

.navController-pageSelectors a{
    text-decoration: none;
}

.navController-pill *{
    transition: all 0.3s ease-in-out;
}
.navController-pill:hover{
    background-color: var(--brandOffColor);
}

.navController-pill:hover h6{
    color: var(--brandColor);
}

.navController-pill:hover img{
    filter: invert(34%) sepia(70%) saturate(3944%) hue-rotate(232deg) brightness(101%) contrast(91%);
}


.navController-pill.selected{
    background-color: var(--brandOffColor);
}

.navController-pill.selected h6{
    color: var(--brandColor);
}

.navController-pill.selected img{
    filter: invert(34%) sepia(70%) saturate(3944%) hue-rotate(232deg) brightness(101%) contrast(91%);
}



.navController-pill img{
    width: 20px;
    height: 20px;
    margin-right: 12px;
}

.navController-pill h6{
    font-size: 14px;
    font-weight: 700;
}

#navControllerPurchase{
    height: 60px;
    width: 100%;
    background-color: var(--brandColor);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 10px;
}

#navControllerPurchase:hover{
    background-color: #7b77fe;
    cursor: pointer;
}

#navControllerPurchase h6{
    color: white;
    font-weight: 500;
}
#navControllerPurchase img{
    width: 30px;
    height: 30px;
    margin-right: 5px;
    margin-left: -10px;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(83deg) brightness(108%) contrast(101%)
}

.navController hr{
    width: 100%;
    height: 2px;
    background-color: var(--lightDark);
}


.navController-socials{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px 0px
}

.navController-socialBtn{
    width: 36px;
    height: 36px;
    padding: 6px 6px;
    background-color: var(--lighterBackground);
    border-radius: 18px;
    margin: 0px 5px;
    background-color: transparent;
}

.navController-socialBtn img{
    width: 24px;
    height: 24px;
    margin-right: 0px;
    transition: all 0.3s ease-in-out;
}

.navController-socialBtn:hover{
    background-color: var(--brandOffColor);
}

.navController-socialBtn:hover h6{
    color: var(--brandColor);
}

.navController-socialBtn:hover img{
    filter: invert(34%) sepia(70%) saturate(3944%) hue-rotate(232deg) brightness(101%) contrast(91%);
}

@media only screen and (max-width: 1000px) {
    .navController{
        width: 175px;
    }
}

@media only screen and (max-width: 900px) {
    .navController{
        z-index: 100;
    }
    .navController.notClosed{
       position: absolute;
    }
}
