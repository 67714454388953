.status-pill{
    width: 100%;
    border: 2px solid var(--lightDark);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 15px;
    border-radius: 20px;
    margin-bottom: 20px;
    max-height: 150px;
}

.status-pill img{
    width: 25px;
    margin-right: 10px;
    filter: invert(34%) sepia(70%) saturate(3944%) hue-rotate(232deg) brightness(101%) contrast(91%);
}

.status-pill h3{
    margin: 0px;
    font-size: 16px;
    font-weight: 500;
    color: var(--mainDark);
    margin-bottom: 2px;
}

.status-pill p{
    overflow-y: scroll;
}
.status-pill.updating h3{
    color: var(--brandColor);
    margin-right: 10px;
}

.status-pill.initializing h3{
    color: var(--brandColor);
}

.status-pill.error img{
    filter: invert(40%) sepia(41%) saturate(5022%) hue-rotate(324deg) brightness(100%) contrast(101%);
}

.status-pill.error h3{
    color: var(--red);
}

.status-pill.success img{
    filter: invert(71%) sepia(67%) saturate(345%) hue-rotate(69deg) brightness(91%) contrast(89%);
}

.status-pill.success h3{
    color: var(--green);
}

.status-pill a{
    margin-top: 5px;
    text-decoration: underline;
}


.status-pill.tree{
    max-height: 2500px !important;
    height: 400px !important;
}

.statusPill-treewrapper{
    margin: 10px 0px;
    width: 100%;
    flex-grow: 1;
    background-color: white;
    border-radius: 10px;
}

.statusPill-treewrapper-download{
    position: absolute;
}


.node__root > circle {
    fill: var(--brandColor);
  }
  
  .node__branch > circle {
    fill: var(--brandColor);
  }
  
  .node__leaf > circle {
    fill: var(--mainDark);
  }
