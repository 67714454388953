.Dropdown-pill {
    height: 100%;
    width: 100%;
    border-radius: 20px;
    border: 2px solid var(--lightDark);
    padding: 0px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}



.Dropdown {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
    border: 2px solid var(--lightDark);
    border-radius: 20px;
    padding: 0px;
    background-color: var(--lighterBackground);
    z-index: 1000;
}

.Dropdown-parent img{
    width: 15px;
}

.Dropdown-parent .rotate180{
    transform: rotate(180deg);
}


.Dropdown-row-hover:hover {
    cursor: pointer;
    background-color: var(--brandOffColor);
}

.Dropdown-row-hover:hover *{
    color: var(--brandColor);
}

.Dropdown-row-hover:hover img{
    filter: invert(34%) sepia(70%) saturate(3944%) hue-rotate(232deg) brightness(101%) contrast(91%)
}

.Dropdown-row{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 0px;
    padding: 0px 15px;
    height: 40px;

}
.Dropdown-row:first-child{
    border-radius: 18px 18px 0px 0px;
}

.Dropdown-row:last-child{
    border-radius: 0px 0px 18px 18px;
}

.Dropdown-row:not(:last-child) {
    border-bottom: 1px solid var(--mainDark);
}