.Home{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 20px;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    container-type: inline-size;
    container-name: sidebar;
}

.home-results-container{
    margin-top: 15px;
    width: 100%;
    height: 525px;
    border-radius: 20px;
    border: 2px solid var(--lightDark);
    transition: all 1s ease-in-out;
}
.home-results-container.growLarge{
    height: 80%;
}


.home-input-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    margin-bottom: 0px;
}

.runButton2{
    height: 26px;
    background-color: var(--brandColor);
    border-radius: 18px;
    display: flex;
    flex-direction: row;
    padding: 2px 18px;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
}

.runButton img{
    width: 24px;
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
}

.runButton h4{
    margin-left: 5px;
    font-weight: 400;
    color: #fff;
}

.home-input-top-row{
    width: 100%;
    border-radius: 18px 18px 0px 0px;
    height: 35px;
    background-color: var(--darkerBackground);
    border-bottom: 2px solid var(--lightDark);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 15px;
    color: var(--mainDark);
}


.home-input-top-row .hover:hover{
    cursor: pointer;
    color: var(--brandColor);
}

.home-sectioned-input{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 40px;
} 

.home-sectioned-input .left{
    background-color: var(--brandOffColor);
    border: 2px solid var(--lightDark);
    border-width: 2px 0px 2px 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    width: 125px;
    justify-content: flex-start;
    padding: 15px;
    border-radius: 20px 0px 0px 20px;
}

.home-sectioned-input .left img{
    height: 20px;
    filter: invert(34%) sepia(70%) saturate(3944%) hue-rotate(232deg) brightness(101%) contrast(91%);
}

.home-firstInputRow{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
}

.home-tinyInputs{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 15px;
    gap: 15px;
}

.home-sectioned-input .left h4{
    font-weight: 600;
    font-size: 14px;
    color: var(--brandColor);
    margin-left: 10px;
}

.home-sectioned-input .input{
    border: 2px solid var(--lightDark);
    flex-grow: 1;
    height: 100%;
    border-radius: 0px 20px 20px 0px;
    padding: 7px 15px;
    resize: none;
}

.home-sectioned-input .input:focus{
    outline: none;
}
.home-tinyInputs .article-count {
    height: 40px;
    width: 175px;
    border-radius: 20px;
    border: 2px solid var(--lightDark);
    padding: 15px 0px 15px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

.home-tinyInputs .article-count * {
    color: var(--mainDark);
}

.home-tinyInputs .article-count label {
    font-size: 14px;
    font-weight: 300;
    width: 1;
}

.article-count input[type="number"]::-webkit-inner-spin-button {
    opacity: 1;
}

.home-tinyInputs .article-count input {
    height: 15px;
    margin-right: 15px;
    width: 50px;
    text-align: center;
    text-decoration: underline;
    padding: 0px;
    margin-left: -10px;
    background-color: transparent;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;

}

.home-tinyInputs .article-count input:focus{
    box-shadow: none !important;
    border: none !important;
    outline: none !important;
}



.home-tinyInputs .mock-container{
    height: 40px;
    width: 200px;
} 


/*first instance of article-count should have no left margin*/
/* .home-tinyInputs .article-count:first-child{
    margin-ri: 0px;
} */

.home-tinyInputs .login-button{
    height: 40px;
    width: 150px;
    font-size: 14px;
    border-radius: 20px;
    color: var(--mainDark);
    background-color: var(--lighterBackground);
    border: 2px solid var(--lightDark);
    padding-left: 7px 15px;
    margin: 15px 15px 0px 0px;
}


.home-tinyInputs .logged-in{
    border-color: var(--brandColor);
    color: var(--brandColor);
    background-color: var(--brandOffColor);
}

.runButton{
    width: 175px;
    height: 36px;
    background-color: var(--brandColor);
    border-radius: 18px;
    display: flex;
    flex-direction: row;
    padding: 0px 15px;
    align-items: center;
    justify-content: flex-start;
}


.runButtonContainer{
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
}

.home-data-body {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    overflow-y: scroll;
}

.home-data-body::-webkit-scrollbar {
  width: 0px; /* Width of the scrollbar */
}

.home-data-body::-webkit-scrollbar-track {
  background: transparent; /* Make it transparent */
}

.home-data-body::-webkit-scrollbar-thumb {
  background: transparent; /* Make it grey (or choose your color) */
  height: px; /* Make it 90% of the track height */
}

.data::-webkit-scrollbar-thumb:hover {
  background: var(--mainDark); /* Color when hovering */
  border-radius: 5px;
}

@media only screen and (max-width: 900px) {
    .Home{
        padding-top: 20px;
    }
    .status-pill{
        max-height: 250px !important;
    }

    .status-pill h3{
        font-size: 12px !important;
    }

    .home-input-top-row p{
        font-size: 10px;
    }

    .runButton{
        width: 150px;
        height: 30px;
    }

    .runButton h4{
        font-size: 12px;
    }

    .runButton img{
        width: 20px;
    }
}