@import url('https://fonts.googleapis.com/css2?family=Lora:wght@500&family=Roboto:wght@300&display=swap');
:root {
    --textColor: #212121;
  }

.launch-HTML{
    width: min(700px, 98%);
    min-height: 0px;
    padding: 15px;
    background-color: var;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 6px 0 rgba(10, 10, 10, 0.6);
}

.launch-HTML * {
    margin-bottom: 20px;
    color: var(--textColor);
}

.launch-HTML h3 {
    font-weight: 500;
    font-size: 24px;
}

.launch-HTML h4 {
    font-weight: 500;
    font-size: 22px;
}

.launch-HTML p {
    font-family: 'Roboto', sans-serif;  
    font-size: 20px;
}


.html-imgWrapeer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.launch-HTML img {
    margin-bottom: 0px;
    width: 100%;
    /* height: 300px; */
    filter: none;
    border-radius: 12px 12px 2px 2px;
}



@media only screen and (max-width: 900px) {

    .launch-HTML h3 {
        font-size: 20px;
    }

    .launch-HTML p {
        font-size: 16px;
    }
}


