.banner-message {
    position: relative;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 0px 0px 20px 20px;
    padding: 10px 25px;
    max-width: 90%;
    width: 800px;
    height: 40px;
    margin-bottom: -40px;
    z-index: 999999;
}

.banner-message p{
    color: #fff;
}

.banner-message.error-message {
    background-color: var(--red);
}

.banner-message.success-message {
    background-color: var(--green);
}

.banner-message>button {
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
}