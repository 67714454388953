.HtmlModal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .HtmlModal {
    background-color: white;
    padding: 20px;
    width: min(800px, 90vw);
    height: min(600px, 90vh);
    border-radius: 8px;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .HtmlModalSpecs {
    background-color: var(--lighterBackground);
    padding: 20px;
    width: min(1000px, 90vw);
    height: min(550px, 90vh);
    border-radius: 8px;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .HtmlModal-toprow{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .HtmlModal-slider{
    position: relative;
    padding: 0px;
    border-radius: 25px;
    height: 30px;
    width: 125px;
    background-color: #f7f7f8;
    display: flex;
    border: 1px solid #d4d4d4;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    top: -42px;
    right: 10px;
  }

  .HtmlModal-slider button:first-child{
    border-radius: 20px 0px 0px 20px;
  }

    .HtmlModal-slider button:last-child{
        border-radius: 0px 20px 20px 0px;
    }

  .HtmlModal-slider button {
    border: none;
    width: 40%;
    height: 100%;
    background-color: transparent;
    color: black;
    font-size: 14px;
  }

  .HtmlModal-slider .HtmlModal-html-button-active{
    width: 60%;
    background-color: black;
    color: white;
    border: 1px solid white;
  }
  
  .close-button {
    position: relative;
    bottom: 15px;
    left: calc(100% - 20px);
    background-color: transparent;
    text-align: center;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: black;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2px;
  }

  .close-button:hover{
    background-color: #f7f7f8;
    border-radius: 17px;
    border: 1px solid #d4d4d4;
    color: var(--brandColor);

  }
  
  .HtmlModal-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: black;
    margin-top: -20px;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .HtmlModal-content  h1{
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .HtmlModal-content  h2{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .HtmlModal-content  h3{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .HtmlModal-content  h4{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .HtmlModal-content  h5{
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .HtmlModal-content  h6{
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .HtmlModal-content  p{
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 15px;
  }

  .HtmlModal-content  a{
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 15px;
    color: var(--brandColor);
  }

  .HtmlModal-content  a:hover{
    text-decoration: underline;
  }

  .HtmlModal-content  ul{
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 15px;
    list-style-type: none;
  }

  .HtmlModal-content  li{
    font-size: 14px;
    font-weight: 400;
    list-style-type: circle !important;
  }

  .HtmlModal-content::-webkit-scrollbar {
    width: 10px;
    background-color: aqua;
    background: transparent;
  }

  .HtmlModal-content * {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: black;
  }
  .HtmlModal-content img{
    max-width: 100% !important;
    height: auto;
    filter: none !important;
  }


  
  body {
    overflow: hidden;
  }
  
  .HtmlModal-overlay::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);
    z-index: 9000;
  }
  