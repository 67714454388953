@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans;
    transition: all 0.3s ease-in-out;
}


:root {
    --brandColor: #625df4;
    --alwaysDark: #212121;
    --brandOffColor: #fff;
    --mainDark: #fff;
    --lightDark: #f7f7f8;
    --darkerBackground: #0e0e0e;
    --lighterBackground: #212121;
    --blackFiler: invert(100%) sepia(100%) saturate(0%) hue-rotate(83deg) brightness(108%) contrast(101%);
    --gray: #757575;
    --green: #6cd06c;
    --red: #fe3d67;
    --paddingTopForScreens: 60px;
  }


  body{
    background-color: var(--lighterBackground);
    overflow-y: hidden;
    overflow-x: hidden;
  }


  #closeButton{
    position: fixed;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    outline: none;
    border: none;
    z-index: 100;
  }


.App {
    width: 100vw;
    height: 100vh;
    padding: 0px;
    background-color: var(--lighterBackground);
    display: flex;
    flex-direction: row;
}

.w-100 {
    width: 100%;
}
.row {
    display: flex;
    flex-direction: row;
}
.wrap {
    flex-wrap: wrap;
}

.column {
    display: flex;
    flex-direction: column;
}

.align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.align-start {
    align-items: flex-start;
}

.justify-start {
    justify-content: flex-start;
}

.align-sb {
    align-items: space-between;
}

.justify-sb {
    justify-content: space-between;
}

.align-end {
    align-items: flex-end;
}

.justify-end {
    justify-content: flex-end;
}

::-webkit-scrollbar {
    width: 10px;
    background-color: var(--lighterBackground);
}

.align-sb {
    transition: all 0s ease !important;
    filter: var(--blackFiler);
}

input {
    background-color: var(--lighterBackground);
}

input::placeholder {
    color: var(--gray);
}

textarea {
    background-color: var(--lighterBackground);
}

textarea::placeholder {
    color: var(--gray);
}

input:focus{
    outline: none;
}

button:disabled:hover {
    cursor: not-allowed !important;
}

button {
    border: none;
}
button:focus{
    outline: none;
}

button:hover{
    cursor: pointer;
}

a:hover{
    cursor: pointer;
}

.nohover:hover{
    cursor: default;
}

img {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
} 
 
.App-right-section{
    background-color: var(--lighterBackground);
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    overflow-x: hidden;
}

.App-right-section .body{
    padding: 15px;
    width: min(100%, 1000px);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.App-right-section.template-purchase .body{
    width: min(100%, 1400px);
}

.App-right-section .launch-body{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.abs-center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.flex-grow-1 {
    flex-grow: 1;
    height: 100%;
}

.Tutorial h1, .Home h1, .Settings h1{
    font-size: 32px;
    font-weight: 600;
    color: var(--mainDark);
}

.Tutorial h3, .Home h3, .Settings h3{
    font-size: 14px;
    font-weight: 700;
    color: var(--mainDark);
}

.Tutorial h4, .Home h4, .Settings h4 {
    font-size: 16px;
    font-weight: 500;
    color: var(--lightDark);
}

.Tutorial h6, .Home h6, .Settings h6{
    font-size: 16px;
    font-weight: 300;
    color: var(--lightDark);
}

.Tutorial p, .Home p, .Settings p{
    font-size: 14px;
    font-weight: 400;
    color: var(--lightDark);
}

.Tutorial a, .Home a {
    font-size: 14px;
    font-weight: 400;
    color: var(--lightDark);
}

.app-btn1{
    width: min(200px, 70%);
    height: 30px;
    border-radius: 15px;
    background-color: var(--brandColor);
    color: #fff;
    font-weight: 600;
    font-size: 14px;
}

.app-btn1:hover{
    background-color: var(--brandOffColor);
    color: var(--brandColor);
}