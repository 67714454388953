.menuButton {
    width: 30px;
    aspect-ratio: 1/1;
    background-color: transparent;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menuButton.topCorner {
    position: absolute;
    top: 10px;
    left: 10px;
}

.topCorner{
    background-color: var(--brandColor);
}
.whiteImg{
    background-color: var(--brandColor);
}
.topCorner:hover{
    background-color: var(--brandColor);
}

.menuButton img {
    width: 75%;
    height: 75%;
    transition: all 0.3s ease-in-out;
}

/* .menuButton:hover {
    background-color: var(--brandOffColor);
}

.menuButton:hover img{
    filter: invert(34%) sepia(70%) saturate(3944%) hue-rotate(232deg) brightness(101%) contrast(91%);
} */

