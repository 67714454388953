.PurchaseScreen{
    width: 100%;
    height: 100%;
    padding-top: var(--paddingTopForScreens);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.PurchaseScreen-card{
    width: 32%;
    height: 400px;
    border: 1px solid var(--lightDark);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.PurchaseScreen-card-holder{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: min(100%, 1000px);
}

.green{
    font-size: 18px;
    margin-right: 4px;
    color: var(--green);
}

.PurchaseScreen-card:first-child{
    border-radius: 10px 0px 0px 10px;
    border-width: 1px 0px 1px 1px;
}
.PurchaseScreen-card:nth-child(2){
    width: 36%;
    height: 430px;
    border-width: 6px;
    border-color: #dbf0ff;
    border-top: 0px solid var(--lightDark);
    border-radius: 0px 0px 10px 10px;
}
.PurchaseScreen-card:last-child{
    border-radius: 0px 10px 10px 0px;
    border-width: 1px 1px 1px 0px;
}

.PurchaseScreen-list-item{
    margin-top: 20px;
    padding-left: 10px;
}
.PurchaseScreen-list-item p{
    margin-bottom: 10px;
    font-size: 13px;
}
.PurchaseScreen-list-black{
    color: var(--mainDark) !important;
}

.PurchaseScreen-mostPopular{
    width: calc(min(100%, 1000px) * 0.36);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #dbf0ff;
    margin-bottom: -1px;
    color: var(--alwaysDark);
    font-weight: 500;
    font-size: 12px;
    border-radius: 10px 10px 0px 0px;
}

.PurchaseScreen-card button{
    width: min(225px, 70%);
    height: 100%;
    border-radius: 20px;
    background-color: var(--brandColor);
    color: #fff;
    font-weight: 600;
    font-size: 14px;
}

.PurchaseScreen-card button:hover{
    background-color: var(--brandOffColor);
    color: var(--brandColor);
}

#PurchaseScreen-button-contact{
    background-color: var(--lighterBackground);
    border: 1px solid var(--mainDark);
    color: var(--mainDark);
    font-weight: 600;
    font-size: 14px;
}

#PurchaseScreen-button-contact:hover{
    background-color: var(--darkerBackground);
}

@media only screen and (max-width: 1000px) {


    .PurchaseScreen-mostPopular{
        display: none;
    }

    .PurchaseScreen-card-holder{
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    .PurchaseScreen-card{
        width: 90% !important;
        border-radius: 10px 10px 10px 10px !important;
        margin: 20px 0px;
        border-width: 1px 1px 1px 1px !important;
        border-top: 1px solid var(--mainDark) !important;
    }

    .PurchaseScreen-card:nth-child(2){        
        border-color: var(--brandColor) !important;
    }

    .shrinkwidth{
        width: 90%;
    }
    .shrinkwidth h1{
        font-size: 30px;
        line-height: 36px;
    }
}