:root {
    --brandColor: #625df4;
    --alwaysDark: #212121;
    --brandOffColor: #fff;
    --mainDark: #fff;
    --lightDark: #f7f7f8;
    --darkerBackground: #0e0e0e;
    --lighterBackground: #212121;
    --blackFiler: invert(100%) sepia(100%) saturate(0%) hue-rotate(83deg) brightness(108%) contrast(101%);
    --gray: #757575;
    --green: #6cd06c;
    --red: #fe3d67;
    --paddingTopForScreens: 60px;
  }

.launch{
    flex: 1;
    max-width: 100%;
    padding: 0px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: auto;
    overflow-y: scroll;
    overflow-x: hidden;
}


.launch::-webkit-scrollbar {
    display: none;
}

.intro-top{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--alwaysDark);
}

.launch-top{
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: transparent;
    display: flex;
}

.introArea h1{
    font-weight: 500;
    font-size: 50px;
    margin-top: 125px;
    text-align: center;
    color: #fff;
}

.htmlWrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 650px;
}

.launch .tryIt{
    border: 1px solid var(--brandColor);
    height: 40px;
    border-radius: 18px;
    width: 175px;
    margin-top: 25px;
    margin-bottom: 40px;
    font-size: 16px;
    background-color: var(--brandColor);
    color: #fff;
}

.howItWorks h2{
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    color: #fff;
    margin-bottom: 15px;
    margin-right: 20px;
}

.howItWorks{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #f9f9f9;
}

.howItWorks h2{
    font-weight: 600;
}

.howItWorks *{
    color: #212121;
}


.howItWorks h2{
    margin: 40px 0px;    
}

.howItWorksRows{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: min(1250px, 100%);
    margin: -40px 0px;
}

.howItWorksRows img{
    filter: none;
    width: 500px;
}

.howIt-left{
    border-radius: 10px;
    padding: 15px;
    width: 45%;
    flex-shrink: 1;
}

.launch-tree{
    width: 750px;
    height: 500px;
}

.launch-tree * {
    font-weight: 300;
    font-size: 14px;
}

.launch-payments{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #212121;
}


.launch-purpleButton{
    margin-top: 25px;
    width: 250px;
    color: white;
    font-size: 14px;
    font-weight: 400;
    height: 36px;
    border-radius: 18px;
    background-color: #625df4;
}



.custom-shape-divider-bottom-1689906032 {
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1689906032 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 143px;
    transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1689906032 .shape-fill {
    fill: #f9f9f9;
}



.custom-shape-divider-bottom-16899060321 {
    margin-top: 125px;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-16899060321 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 143px;
    transform: rotateY(180deg);
}

.custom-shape-divider-bottom-16899060321 .shape-fill {
    fill: #212121;
}

.custom-shape-divider-bottom-1689915193 {
    margin-top: 150px;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1689915193 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 152px;
}

.custom-shape-divider-bottom-1689915193 .shape-fill {
    fill: #625df4;
}

.launch-demo{
    width: 100%;
    background-color: #625df4;
}

.treesSection{
    height: 500px;
}

@media only screen and (max-width: 900px) {

    .howItWorks h2{
        font-size: 32px;
        line-height: 36px;
    }
    .howItWorksRows{
        margin: 40px 0px;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    .treesSection{
        height: 800px;
        margin-top: -40px;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: flex-start;
    }

    .howIt-left{
        width: 95%;
    }

    .howItWorksRows img{
        width: 400px;
    }

    .launch-tree{
        width: 650px;
    }
}

