.specifications{
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px;
    margin: 0px;
}

.specs-saveBtn{
    position: absolute;
    top: 10px;
    right: 10px;
    font-weight: 300;
    color: var(--mainDark);
    background-color: var(--darkerBackground);
    padding: 5px 15px;
    border-radius: 5px;
}

.specs-saveBtn:hover{
    cursor: pointer;
    background-color: var(--brandOffColor);
    color: var(--brandColor);
}


.specificatication-inputs{
    width: 100%;
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-y: scroll;
    margin-top: 20px;
}

.spec-move-btn{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.spec-move-back{
    position: absolute;
    bottom: 10px;
    left: 10px;
}

.spec-move-forward{
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.specifications h2{
    margin-bottom: 20px;
}

.specificatication-inputs label{
    font-weight: 300;
    font-size: 20px;
    margin-bottom: 10px;
}

.specificatication-inputs-input{
    border: 1px solid var(--lightDark);
    font-size: 16px !important;
    height: 40px;
    margin-left: 2px;
    width: 99%;
    flex-shrink: 1;
    border-radius: 10px !important;
    padding: 7px 15px !important;
    resize: none;
}

.specificatication-inputs-input::placeholder{
    font-weight: 300;
}

.specificatication-inputs-input:focus{
    outline: none;
    border: 1px solid var(--brandColor) !important;
}

textarea.specificatication-inputs-input{
    height: 200px;
}

.insights-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    width: 100%;
}

.insights-row button{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    text-align: center;
    font-size: 20px;
    background-color: var(--darkerBackground);
    color: var(--mainDark);
    margin-left: 20px;
}

.insights-row button:hover{
    cursor: pointer;
    background-color: var(--brandOffColor);
    color: var(--brandColor);
}


.specificatication-input-xsmall{
    width: calc(40% - 60px);
}

.specificatication-input-small{
    width: calc(60% - 60px);
}

.specificatication-input-xsmall:first-of-type{
    margin-right: 20px;
}

@media only screen and (max-width: 900px) {
    .app-btn1{
        width: 80px;
    }

    .specs-saveBtn{
        position: absolute;
        top: -40px;
    }

    .insights-row input{
        height: 30px;
    }

    .insights-row input::placeholder{
        font-size: 10px;
    }

    .specificatication-input-xsmall{
        width: calc(40% - 30px);
    }
    
    .specificatication-input-small{
        width: calc(60% - 30px);
    }

    .insights-row button{
        width: 15px;
        height: 15px;

    }
}