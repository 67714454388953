.login-with-google-btn {
    border: 1px solid #6d758d;
    border-radius: 2px;
    height: 48px;
    padding: 12px 12px;
    width: 100%;
    border-radius: 3px;
    font-size: 16px;
    font-weight: 300;
    text-align: left;
    text-indent: 30px;
    color: #6d758d;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: white;
    background-repeat: no-repeat;
    background-position: 12px 14px;
    margin-top: 0cqb;
}

@media screen and (max-width: 1000px) {
    .login-with-google-btn {
        font-size: 12px;
    }
}

.login-with-google-btn:hover {
    background-color:#e0e1f7;
    cursor: pointer;
}

#authSvg {
    margin-bottom: 10px;
    background-color: var(--offwhite);
}

.Auth-loggedIn{
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    border-radius: 3px;
    background-color: transparent;
    font-size: 14px;
    color: #6d758d;
    border: 1px solid #6d758d;
}

.Auth-loggedIn:hover{
    background-color:#e0e1f7;
    cursor: pointer;
}

.Auth-loggedIn img {
    height: 100%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    filter: none !important;
    margin-right: 15px;
}